import React, { useState, useEffect } from "react";
import { Box, Button, Snackbar, Card, Tabs, Tab } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import axios from "axios";
import Modal from '@mui/material/Modal';
import useStore from "../../store";
import { Popper, Paper, InputBase } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { Menu, MenuItem, Select,  Dialog,DialogTitle, DialogContent,DialogActions,TextField, Drawer, Typography } from "@mui/material";
import { display } from "@mui/system";
import TabProperties from "../tabs/tabs";
import { IconButton } from "@mui/material";
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import realPageLogo from "../../images/realpage-logo.png";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid-pro';
import SaveIcon from '@mui/icons-material/Save';
import Popover from '@mui/material/Popover';
import ProrationCalculator from "../prorationCalculator";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import MapIcon from '@mui/icons-material/Map';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhoneIcon from '@mui/icons-material/Phone';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Switch from '@mui/material/Switch';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CircularProgress from '@mui/material/CircularProgress';
import CalculateIcon from '@mui/icons-material/Calculate';
import StarsIcon from '@mui/icons-material/Stars';
const Properties = ({
  properties,
  setFilteredProperties,
  paginationModel,
  setPaginationModel,
  totalRows,
  setSortModel,
  sortModel,
  fetchData,
  loading,
  unitFilter,
  selectedBoolean,
  refreshTable,
  driveData
}) => {
  const user = useStore((state) => state.user);
  const defaultVisibleColumns = [
    "name",
    "virtual_tour",
    "virtual_tour_edit",
    "submarket",
    "year_built_renovated",
    "special_toggle",
    "special",
    "send",
    "escort",
    "bonus",
    "studio_price",
    "one_bed_price",
    "two_bed_price",
    "three_bed_price",
    "four_plus_bed_price",
    "category",
    "apartment_url"
  ];
  const [bedCount, setBedCount] = useState({
    studio:0,
    one_bed: 0,
    two_bed: 0,
    three_bed: 0,
    four_plus_bed: 0
  });
  const [width, setWidth] = useState(400);
  const [modalLoader, setModalLoader] = useState(false);
  const [specialData, setSpecialData] = useState({});
  const [termLengthProp, setTermLengthProp] = useState();
  const [monthFeeProp, setMonthFeeProp] = useState();
  const [monthFeePropReferenceProp, setMonthFeeReferenceProp] = useState();
  const [rentConcessionProp, setRentConcessionProp] = useState();
  const [rentConcessionReferenceProp, setRentConcessionReferenceProp] = useState();
  const [currentId, setCurrentId] = useState({});
  const [isSightMap, setIsSightMap] = useState(false);
  const [currentRowData, setCurrentRowData] = useState({});
  const [hideTab, setHideTab] = useState(true);
  const [notAvailable, setNotAvailable] = useState(false);
  const [tempUnit, setTempUnit] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCategory, setShowCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [propertyId, setPropertyId] = useState(0);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [currentUnit, setCurrentUnit] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState([]);
  const [data, setData] = useState('');
  const [currentSelectedData, setCurrentSelectedData] = useState({});
  const [isPopupDoubleClickOpen, setIsDoubleClickPopupOpen] = useState(false);
  const [selectedCellData, setSelectedCellData] = useState(null);
  const [localLink, setLocalLink] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: []
  });

  const handleDataFromChild = (childData) => {
    setData(childData);
  };
  const handleVirtual = (modal, bed) => {
    setLocalLink(prevState => ({
      ...prevState,
      [bed]: [
        ...(prevState[bed] || []),
        modal,
      ],
    }));
  };

  const openAllFloorplanLinks = () => {
    selectedRows.forEach((row) => {
      if (row.floorplan_link) {
        window.open(row.floorplan_link, "_blank", "noopener noreferrer");
      }
    });
  };
  const [contextMenu, setContextMenu] = React.useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
    setShowCategory(null);
  };
  const handleCategoryChange = (category) => {
    setShowCategory(category);
    setSubMenuOpen(true);
  };
  const handleApplyCategory = (category) => {
    setSelectedCategory(category);
    handleClose();
  };
  const handleSubMenuClose = () => {
    setSubMenuOpen(false);
  };

  useEffect(() => {
    if (selectedCategory) {
      let updatedCategory = selectedCategory;
      if (selectedCategory === "null") {
        updatedCategory = null;
      }
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        category: updatedCategory,
      }));
      setSelectedRows(updatedRows);
      if (updatedRows.length === 1) {
        const singleRow = updatedRows[0];
        handleRowUpdate(singleRow);
      } else {
        handleMultiRowUpdate(updatedRows);
      }
      setSelectedCategory("");
    }
  }, [selectedCategory]);
  const copyProperties = () => {
    const propertiesList = selectedRows
      .map((row) => {
        const name = row.name;
        const website = row.website || "#";
        return `<a href="${website}" target="_blank">${name}</a>`;
      })
      .join("<br>"); // Use <br> to create a new line for each property link in HTML

    // Convert the list into HTML format
    const html = `<html><body>${propertiesList}</body></html>`;
    const blob = new Blob([html], { type: "text/html" });
    const data = [new ClipboardItem({ "text/html": blob })];

    navigator.clipboard
      .write(data)
      .then(() => {
        console.log("Property links copied to clipboard in column format!");
        setSnackbarOpen(true); // Show confirmation via snackbar
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const handleCancel = (cancel) => {
    //refreshTable(2000);
    setIsDropdownOpen(false);
  };

  const reduceWidth = (cancel) => {
    setWidth(cancel)
  };

  const handleSubmit = async (submit, leaseTerm, monthsFree, rentConcession, monthsFreeReference, rentConcessionReference, bool) => {
    try {
      setSpecialData({});
      setSpecialData((prev) => ({
        ...prev,
        leaseTerm: leaseTerm,

        monthsFree: monthsFree,

        monthsFreeReference: monthsFreeReference,

        rentConcession: rentConcession,

        rentConcessionReference: rentConcessionReference,
      }));
      if(currentId?.id > 0 || submit?.id > 0)
      {
        // if(currentId?.id)
        // {
        //   currentId.special_toggle = !currentId?.special_toggle;
        //   // currentId.special_calculation_price = parseFloat(convertedTax);
        //   // currentId.special_calculation_text = currentId?.special?.replace("special= $", `special= $${convertedTax}`);
        // }
        setModalLoader(true);
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/property-unit/${currentId?.id ?? submit?.id}`, { leaseTerm, monthsFree, rentConcession, monthsFreeReference, rentConcessionReference, specialToggle: bool }
        ).then((res) => {
          refreshTable(2000);
          setModalLoader(false);
          setIsDropdownOpen(false);
        });
        // viewDetails(setCurrentIndex, true);
        // setIsDropdownOpen(false);
        return response?.data;
      }
    } catch (error) {
      console.error("Error updating row:", error);
      throw new Error("Could not update row. Please try again.");
    }
  };

  const renderActionButtons = (params) => {
    // You can access the row data using params.row
    // Add any button or action component you need here
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleActionOne(params.row);
        }}
        variant="contained"
        color="primary"
        size="small"
        style={{ minWidth: '40px' }} 
      >
        *
      </Button>
    );
  };

  const renderBlacklistButton = (params) => {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleActionTwo(params.row);
        }}
        variant="contained"
        color="primary"
        size="small"
      >
        Blacklist
      </Button>
    );
  };

  const handleActionTwo = async (rowData) => {
    //setCurrentRow(rowData);
    if (!user || user.role !== "admin") {
      console.log("User is not authorized to update rows.");
    } else {
      try {
        // Make an API call to update the backend
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/property/${rowData.id}`,
          { blacklist: !rowData.blacklist }
        );
        return response.data;
      } catch (error) {
        console.error("Error updating row:", error);
        throw new Error("Could not update row. Please try again.");
      }
    }
  };

  // Function to handle an action (example)
  const handleActionOne = (rowData) => {
    // Define HTML content with inline styles
    const emailContent = `
      <div style="font-size: 16px; font-family: 'Calibri', sans-serif;">
        <p style="font-weight: 700; margin: 0; padding: 0"><a href="${
          rowData?.website
        }">${rowData?.name ? (rowData?.name + " (" + rowData.website+ ")") : " "}</a>${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "")}</p>
        <p style="margin: 0; padding: 0">Price: $ <span style="font-weight: 700;">${
          rowData?.special ?? ""
        }</span></p>
        <p style="margin: 0; padding: 0">Size: sq ft 1/1</p>
        <p style="margin: 0; padding: 0">Unit: #</p>
        <p style="margin: 0; padding: 0">Available: - ${
          rowData?.hold_time ? rowData?.hold_time : ""
        }</p>
        <p style="margin: 0; padding: 0">Location: ${
          rowData?.submarket ? rowData?.submarket : ""
        }</p>
        <p style="margin: 0; padding: 0">${
          rowData?.last_line ? rowData?.last_line : ""
        }</p>
      </div>
    `;
  
 const plainTextContent = `${rowData?.name ? `${rowData.name} (${rowData.website}) ${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "*if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit" : "")}` : ""}
Price: $ ${rowData?.special ?? ""}
Size: sq ft 1/1
Unit: #
Available: - ${rowData?.hold_time ? rowData?.hold_time : ""}
Location: ${rowData?.submarket ? rowData?.submarket : ""}
${rowData?.last_line ? rowData?.last_line : ""}`;

    // Use the Clipboard API to write HTML
    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/html": new Blob([emailContent], { type: "text/html" }),
          "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
        }),
      ])
      .then(() => {
        console.log("Email info copied to clipboard!");
        setSnackbarOpen(true); // Open the Snackbar
      })
      .catch((err) => {
        console.error("Failed to copy email info: ", err);
        // Handle error (e.g., display an error message)
      });
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevents the snackbar from closing when the user clicks outside of it
    }
    setSnackbarOpen(false); // Close the Snackbar
  };

const [linkSnackBarOpen,setLinkSnackBarOpen]=useState(false)
    const handleVirtualTourLinkCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Virtual tour link copied to clipboard:", link);
        setLinkSnackBarOpen(true);
      })
      .catch((error) => {
        console.error("Failed to copy virtual tour link:", error);
      });
  };
  const handleCloseLinkSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; 
    }
    setLinkSnackBarOpen(false);
  };
  const renderCellContent = (params) => {
    const { field, value, row } = params;
    if (field === "category" && (value === null || value === undefined)) {
      return "-";
    }
    if (field === "name" && row?.floorplan_link) {
      return (
        <Link
          href={row?.floorplan_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </Link>
      );
    }

    if (field === "year_built_renovated") {
      const yearBuilt = row.year_built;
      const yearRenovated = row.year_renovated;
      const displayValue = yearRenovated
        ? `${yearBuilt} / ${yearRenovated}`
        : `${yearBuilt}`;
      return displayValue;
    }
    if (field.includes('email') ) {
      const handleEmailClick = (event) => {
        event.stopPropagation()
        navigator.clipboard
          .writeText(value)
          .then(() => {
            console.log("Email copied to clipboard:", value);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("Failed to copy email:", error);
          });
      };
  
      return (
        <span href="#" onClick={handleEmailClick} style={{ color: '#1976d2', textDecoration: 'underline' }}>
          {value}
        </span>
      );
    }
    if (field === "actions_copy_mail") {
      return (
        <div className="custom-select">
          <Select
            style={{ width: 100, color: "#989ca3" }}
            value={null}
            displayEmpty
          >
            <MenuItem value={null} disabled>
              Show Emails
            </MenuItem>
            {row.email_1 != null && row.email_1 != "" ? (
              <MenuItem
                className="line-clamp-1"
                key={row.email_1}
                value={row.email_1}
                onClick={() => handleVirtualTourLinkCopy(row.email_1)}
              >
                {row.email_1}
              </MenuItem>
            ) : (
              ""
            )}
            {row.email_2 != null && row.email_2 != "" ? (
              <MenuItem
                className="line-clamp-1"
                key={row.email_2}
                value={row.email_2}
                onClick={() => handleVirtualTourLinkCopy(row.email_2)}
              >
                {row.email_2}
              </MenuItem>
            ) : (
              ""
            )}
            {row.email_3 != null && row.email_3 != "" ? (
              <MenuItem
                className="line-clamp-1"
                key={row.email_3}
                value={row.email_3}
                onClick={() => handleVirtualTourLinkCopy(row.email_3)}
              >
                {row.email_3}
              </MenuItem>
            ) : (
              ""
            )}
            {row.email_4 != null && row.email_4 != "" ? (
              <MenuItem
                className="line-clamp-1"
                key={row.email_4}
                value={row.email_4}
                onClick={() => handleVirtualTourLinkCopy(row.email_4)}
              >
                {row.email_4}
              </MenuItem>
            ) : (
              ""
            )}
          </Select>
        </div>
      );
    }
    if (field === "virtual_tour") {
      // console.log(row?.virtual_tour, "row?.virtual_tour");
      return (
        <div className="custom-select">
        <Select style={{ width: 100 , color:"#989ca3"}} value={null} displayEmpty>
          <MenuItem value={null} disabled>
          Show Links
          </MenuItem>
          {row?.virtual_tour !== undefined && row?.virtual_tour !== null ? (
            row?.virtual_tour.map((tour) => (
              <MenuItem
              className="line-clamp-1"
                key={tour?.id}
                value={tour?.link}
                onClick={() => handleVirtualTourLinkCopy(tour?.link)}
              >
               {tour?.name ? tour?.name : (tour?.link?.length > 25 ? tour?.link?.slice(0, 25) + "..." : tour?.link)}
              </MenuItem>
            ))
          ) :  (
            <MenuItem value='' >
              -
            </MenuItem>)}
            {driveData && driveData.length > 0 ?
             (driveData.filter((x) => x?.Key?.includes(row?.market?.toLowerCase()) && x?.Key?.includes(row?.name?.toLowerCase() + "/") && !x?.Key?.includes("-thumb-")).map((tour) => (
              <MenuItem
                  className="line-clamp-1"
                  key={tour.Type == "folder"
                    ? window.location.origin +
                      "/folder?folderUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )
                    : tour.Type == "document" || tour.Type == "image"
                    ? window.location.origin +
                      "/" +
                      tour.Type +
                      "?documentUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )
                    : window.location.origin +
                      "/video?videoUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )}
                  value={tour.Type == "folder"
                    ? window.location.origin +
                      "/folder?folderUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )
                    : tour.Type == "document" || tour.Type == "image"
                    ? window.location.origin +
                      "/" +
                      tour.Type +
                      "?documentUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )
                    : window.location.origin +
                      "/video?videoUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )}
                  onClick={() => handleVirtualTourLinkCopy(tour.Type == "folder"
                    ? window.location.origin +
                      "/folder?folderUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )
                    : tour.Type == "document" || tour.Type == "image"
                    ? window.location.origin +
                      "/" +
                      tour.Type +
                      "?documentUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      )
                    : window.location.origin +
                      "/video?videoUrl=" +
                      btoa(
                        encodeURIComponent(tour?.original)
                      ))}
                >
                  {tour?.original?.split("/")[tour?.original?.split("/").length - 1]}
                </MenuItem>
             ))) 
             : 
             ("")}
        </Select>
        </div>
      );
    }
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    if (typeof value === "string") {
      // Check for URL
      try {
        const url = new URL(value);
        if (url.protocol === "http:" || url.protocol === "https:") {
          return (
            <Link href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </Link>
          );
        }
      } catch (error) {
        // Not a URL
      }
      // Check for email
      if (value.includes("@") && value.includes(".")) {
        return <Link href={`mailto:${value}`}>{value}</Link>;
      }
      // Check for phone numbers (basic validation)
      const phoneRegex = /^\d{10}$/;
      if (
        phoneRegex.test(value.replace(/[\s\-\(\)]/g, "")) &&
        value.replace(/[\s\-\(\)]/g, "").length === 10
      ) {
        return <Link href={`tel:${value}`}>{value}</Link>;
      }
    }
    return value;
  };

  const excludeColumns = [
    "lat",
    "lng",
    "google_place_id",
    "google_place_name",
    "google_operating_hours",
    "google_place_types",
    "price_min",
    "price_max",
    "year_built",
    "year_renovated",
  ]; // Replace with the keys of columns you want to exclude

  const generateColumnVisibilityModel = () => {
    const visibilityModel = {};
    if (properties.length > 0) {
      Object.keys(properties[0]).forEach((key) => {
        visibilityModel[key] = defaultVisibleColumns.includes(key);
      });
    }

    return visibilityModel;
  };
  const isEmpty = (val) => [undefined, null, ""].includes(val);
  const RenderYearBuiltRenovated = (params) => {
    const { row } = params;
    let singleRowData = { ...row };

    const [builtDate, setBuiltDate] = useState(row?.year_built);
    const [renovatedDate, setRenovatedDate] = useState(row?.year_renovated);
    const [yearClick, setYearClick] = useState(0);
    const handleYearBuiltChange = (event) => {
      setBuiltDate(event?.target?.value);
    };

    const isHighlighted = selectedRows?.id === row?.id;

    const handleYearRenovatedChange = (event) => {
      setRenovatedDate(event?.target?.value);
    };
    const handleBuiltSave = () => {
      setYearClick(0);
      if (builtDate === row?.year_built) return;

      singleRowData.year_built = builtDate;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            year_built: builtDate,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };
    const handleRenovatedSave = () => {
      setYearClick(0);
      if (renovatedDate === row?.year_renovated) return;

      singleRowData.year_renovated = renovatedDate;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            year_renovated: renovatedDate,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };
    const handleBuiltEnter = (event) => {
      if (event.key === "Enter") {
        setYearClick(0);

        if (builtDate === row?.year_built) return;

        singleRowData.year_built = builtDate;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              year_built: builtDate,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };
    const handleRenovatedEnter = (event) => {
      if (event.key === "Enter") {
        setYearClick(0);

        if (renovatedDate === row.year_renovated) return;

        singleRowData.year_renovated = renovatedDate;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              year_renovated: renovatedDate,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    return (
      <div
        className={`group-hover/row:bg-neutral-100 ${
          isHighlighted ? "bg-cyan-800" : ""
        }`}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
        onClick={() => setYearClick(yearClick + 1)}
      >
        {yearClick > 1 ? (
          <>
            {" "}
            <div>
              <input
                type="text"
                value={builtDate || ""}
                className="focus:outline-none border-0 group-hover/row:bg-neutral-100"
                onChange={handleYearBuiltChange}
                onBlur={handleBuiltSave}
                onKeyPress={handleBuiltEnter}
                placeholder="-"
              />
            </div>
            <div>
              <input
                type="text"
                className="focus:outline-none border-0 group-hover/row:bg-neutral-100"
                value={renovatedDate || ""}
                onChange={handleYearRenovatedChange}
                onBlur={handleRenovatedSave}
                onKeyPress={handleRenovatedEnter}
                placeholder="-"
              />
            </div>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {builtDate > 0 && <span>{builtDate}</span>}
            {renovatedDate > 0 && <span>{renovatedDate}</span>}
          </div>
        )}
      </div>
    );
  };
  const EditMultilineText = (props) => {
    const { id, field, value, colDef } = props;
    const [valueState, setValueState] = useState(value);
    const [anchorEl, setAnchorEl] = useState();
    const [inputRef, setInputRef] = useState(null);
    const apiRef = useGridApiContext();

    const handleRef = (el) => {
      setAnchorEl(el);
    };

    const handleChange = (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    };

    return (
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={4}
                value={valueState}
                sx={{
                  textarea: { resize: "both", fontSize: "15px" },
                  width: "100%",
                }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  };

  const renderSpecialCellContent = (params) => {
    const { value } = params;
    return (
      <div className="whitespace-pre-wrap overflow-hidden line-clamp-2">
        {" "}
        {value}
      </div>
    );
  };
  const EditSendField = (params) => {
    const { row } = params;
    let singleRowData = { ...row };
    const [sendClick, setSendClick] = useState(0);
    const [send, setSend] = useState(row?.send);
    const [sendType, setSendType] = useState(row?.send_type);

    const handleSendChange = (event) => {
      setSend(event?.target?.value);
    };

    const isHighlighted = selectedRows?.id === row?.id;

    const handleSendSave = () => {
      setSendClick(0);
      if (send === row?.send) return;

      singleRowData.send = send;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            send: send,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleSendTypeChange = (event) => {
      setSendType(event?.target?.value);
    };

    const handleSendTypeSave = () => {
      if (sendType === row?.send_type) return;
      setSendClick(0);
      singleRowData.send_type = sendType;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            send_type: sendType,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleSendEnter = (event) => {
      if (event.key === "Enter") {
        setSendClick(0);
        if (send === row?.send) return;

        singleRowData.send = send;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              send: send,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const handleSendTypeEnter = (event) => {
      if (event.key === "Enter") {
        setSendClick(0);
        if (sendType === row.send_type) return;

        singleRowData.send_type = sendType;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              send_type: sendType,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const type = getType(row.send_type);

    let valueDisplay = null;
    if (send > 0) {
      if (type === "$") {
        valueDisplay = `${type}${send}`;
      } else {
        valueDisplay = `${send}${type}`;
      }
    }

    return (
      <div
        className={`group-hover/row:bg-neutral-100  ${
          isHighlighted ? "bg-cyan-800" : ""
        }`}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
        onClick={() => setSendClick(sendClick + 1)}
      >
        {sendClick > 1 ? (
          <>
            {" "}
            <div>
              <input
                type="number"
                value={send || ""}
                style={{ width: "100%" }}
                className="focus:outline-none border-0 group-hover/row:bg-neutral-100 w-[130px]"
                onChange={handleSendChange}
                onBlur={handleSendSave}
                onKeyPress={handleSendEnter}
                placeholder="-"
              />
            </div>
            <div>
              <select
                className="focus:outline-none border-1 w-[130px]  group-hover/row:bg-neutral-100"
                style={{ width: "100%" }}
                value={sendType || "percentage"}
                onChange={handleSendTypeChange}
                onBlur={handleSendTypeSave}
                onKeyPress={handleSendTypeEnter}
              >
                <option value="percentage">%</option>
                <option value="dollar">$</option>
              </select>
            </div>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ height: "50px", paddingTop: "15px" }}>
              {valueDisplay}
            </span>
          </div>
        )}
      </div>
    );
  };
  const EditEscortField = (params) => {
    const { row } = params;
    let singleRowData = { ...row };

    const [escort, setEscort] = useState(row?.escort);
    const [escortType, setEscortType] = useState(row?.escort_type);
    const [yearClick, setYearClick] = useState(0);

    const handleEscortChange = (event) => {
      setEscort(event?.target?.value);
    };

    const isHighlighted = selectedRows?.id === row?.id;

    const handleEscortSave = () => {
      setYearClick(0);
      if (escort === row?.escort) return;

      singleRowData.escort = escort;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            escort: escort,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleEscortTypeChange = (event) => {
      setEscortType(event?.target?.value);
    };

    const handleEscortTypeSave = () => {
      setYearClick(0);
      if (escortType === row?.escort_type) return;

      singleRowData.escort_type = escortType;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            escort_type: escortType,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleEscortEnter = (event) => {
      if (event.key === "Enter") {
        setYearClick(0);
        if (escort === row?.escort) return;

        singleRowData.escort = escort;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              escort: escort,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const handleEscortTypeEnter = (event) => {
      if (event.key === "Enter") {
        setYearClick(0);
        if (escortType === row.escort_type) return;

        singleRowData.escort_type = escortType;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              escort_type: escortType,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const type = getType(row.escort_type);

    let valueDisplay = null;
    if (escort > 0) {
      if (type === "$") {
        valueDisplay = `${type}${escort}`;
      } else {
        valueDisplay = `${escort}${type}`;
      }
    }
    return (
      <div
        className={`group-hover/row:bg-neutral-100  ${
          isHighlighted ? "bg-cyan-800" : ""
        }`}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 2,
        }}
        onClick={() => setYearClick(yearClick + 1)}
      >
        {yearClick > 1 ? (
          <>
            {" "}
            <div>
              <input
                type="number"
                value={escort || ""}
                className="focus:outline-none border-0 group-hover/row:bg-neutral-100 w-[130px]"
                onChange={handleEscortChange}
                onBlur={handleEscortSave}
                onKeyPress={handleEscortEnter}
                placeholder="-"
              />
            </div>
            <div>
              <select
                className="focus:outline-none border-1 w-[130px] group-hover/row:bg-neutral-100"
                value={escortType || "percentage"}
                onChange={handleEscortTypeChange}
                onBlur={handleEscortTypeSave}
                onKeyPress={handleEscortTypeEnter}
              >
                <option value="percentage">%</option>
                <option value="dollar">$</option>
              </select>
            </div>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ height: "50px", paddingTop: "15px" }}>
              {valueDisplay}
            </span>
          </div>
        )}
      </div>
    );
  };

  function getType(value) {
    if (value === "percentage") {
      return "%";
    } else if (value === "dollar") {
      return "$";
    } else {
      return "";
    }
  }
  const multilineColumn = {
    type: "string",
    renderEditCell: (params) => <EditMultilineText {...params} />,
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editId, setEditId] = useState();

  const handleEditIconClick = (id) => {
    setEditId(id);
    setIsPopupOpen(true);
  };
  const [tours, setTours] = useState([]);
  useEffect(() => {
    const filteredProperties = properties.filter(
      (property) => property?.id === editId
    );
    const mappedTours = filteredProperties
      .map((property) => property?.virtual_tour)
      .flat()
      if(mappedTours?.length > 0 && mappedTours[0] !== null){
         setTours(mappedTours);
      }
      else{
        setTours([{name:"",link:""}])
      }
  }, [properties, editId]);
 
  const handleChange = (propertyIndex, field, value) => {
    const updatedTours = [...tours];
    updatedTours[propertyIndex][field] = value;
    setTours(updatedTours);
  };

  const handleRemove = (propertyIndex) => {
    const updatedTours = [...tours];
    updatedTours.splice(propertyIndex, 1);
    setTours(updatedTours);
  };

  const handleAdd = () => {
    setTours([...tours, { name: "", link: "" }]);
  };
  const handleSavePopup = () => {
    const updatedIndex = properties.findIndex(
      (property) => property?.id === editId
    );
    const filteredVirtualFields = tours.filter((field) => field?.link);
    if (updatedIndex >= 0) {
      const updatedProperty = {
        ...properties[updatedIndex],
        virtual_tour: filteredVirtualFields,
      };

      handleRowUpdate(updatedProperty)
    }

    setIsPopupOpen(false);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const customWidthColumns = [
    "state", "id", "zip_code", "market", "studio_price", "one_bed_price", "submarket",
    "two_bed_price", "three_bed_price", "four_plus_bed_price", "admin_fee", 
    "application_fee", "number_floors", "single_family", "townhome", "send","escort","bonus",
    "high_rise", "studio", "one_bed", "two_bed", "three_bed", "four_plus_bed", 
    "yard", "garage", "loft" , "study", "desk", "ev_charger", "fireplace", "no_carpet", "year_built_renovated",
    "tub_shower", "industrial","concrete_floors", "furnished", "high_ceilings", 
    "terrace", "roof_terrace","floor_to_ceiling_windows", "concierge", 
    // "blacklisted",
    "smart",
     "one_and_a_half_bath"
  ];
  // Function to generate columns based on the order in defaultVisibleColumns
  const generateColumns = () => {
    let orderedColumns = [
      {
        field: "actions",
        headerName: "Copy",
        width: 60,
        renderCell: renderActionButtons,
      },
      {
        field: "actions_copy_mail",
        headerName: "Email",
        width: 70,
        renderCell: renderCellContent,
      },
      {
        field: "virtual_tour",
        headerName: "Virtual Tour",
        width: 100,
        sortable: false,
        renderCell: renderCellContent,
      },
      {
        field: "virtual_tour_edit",
        type: "actions",
        width: 20,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon style={{color:"#0075FF"}}/>}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditIconClick(id)}
              color="inherit"
            />,
          ];
        },
      }
    ];
  let columnConfig ={}
    // First add columns from defaultVisibleColumns
    defaultVisibleColumns.forEach((col) => {
      if(col !== "virtual_tour" && col !== "virtual_tour_edit"){
      const isCustomWidthColumns = customWidthColumns.includes(col)
       columnConfig = {
        field: col,
        headerName:
          col == "special_toggle" ? "" : col.charAt(0).toUpperCase() + col?.slice(1).replace(/_/g, " "),
        width: col === "special_toggle" || col === "year_built_renovated" ? 70 : isCustomWidthColumns ? 100 : col === "special" ? 220 : 190,
        renderCell:
          col === "special_toggle" ? renderViewToggleButton : 
          col === "special"
            ? renderSpecialCellContent
            : col === "year_built_renovated"
            ? RenderYearBuiltRenovated
            : col === "send"
            ? EditSendField
            : col === "escort"
            ? EditEscortField
            : renderCellContent,
        ...(col === "special" ? { ...multilineColumn } : {}),
      };
    }
      if (
        col === "bonus" ||
        col === "studio_price" ||
        col === "one_bed_price" ||
        col === "two_bed_price" ||
        col === "three_bed_price" ||
        col === "four_plus_bed_price"
      ) {
        columnConfig.renderCell = (params) => (
          <span>{!isEmpty(params?.value) ? `$${params?.value}` : ""}</span>
        );
      }
      //  else if (col === "escort" || col==="send") {
      //   columnConfig.renderCell = (params) => {
      //     const type = getType(params.row[`${col}_type`]);

      //     let valueDisplay;
      //     if (type === "$") {
      //       valueDisplay = `${type}${params?.value}`;
      //     } else {
      //       valueDisplay = `${params?.value}${type}`;
      //     }

      //     return <span>{!isEmpty(params?.value) ? valueDisplay : ""}</span>;
      //   };
      // }
      if (col === "category") {
        columnConfig.renderEditCell = (params) => (
          <Select
            className="w-full "
            value={params.value || ""}
            onChange={(e) =>
              {
                handleApplyCategory(e.target.value);
                handleRowUpdate(currentSelectedData?.row, {}, currentSelectedData?.field, e.target.value)
                params.api.setEditCellValue({ ...params, value: e.target.value })
              }
              }
          >
            {" "}
            <MenuItem value={"null"}>-</MenuItem>
            <MenuItem
              value={"Not working with locators"}
            >
              Not working with locators
            </MenuItem>
            <MenuItem  value={"Student Housing"}>
            Student Housing
            </MenuItem>
            <MenuItem  value={"Affordable Housing"}>
            Affordable Housing
            </MenuItem>
            <MenuItem  value={"Do not add"}>
            Do not add
            </MenuItem>
            <MenuItem  value={"Unsure"}>
              Unsure
            </MenuItem>
          </Select>
        );
      }
      
  
      

      columnConfig.editable =
        col !== "year_built_renovated" && col !== "send" && col !== "escort" ;

      // if (col === "send") {
      //   columnConfig.sortComparator = customSortComparator;
      // }

      orderedColumns.push(columnConfig);
    });
  
    // Then add the remaining columns
    if (properties.length > 0) {
      Object.keys(properties[0])
        .filter(
          (key) =>
            !defaultVisibleColumns.includes(key) &&
            !excludeColumns.includes(key)
        )
        .forEach((key) => {
          if (key !== "escort_type" && key !== "send_type" && key !== "phone" && key !=="number_units") {
            const isCustomWidthColumns = customWidthColumns.includes(key)
            let columnDef = {
              field: key,
              headerName:
                key.charAt(0).toUpperCase() + key?.slice(1).replace(/_/g, " "),
              editable: true,
              width: key == "year_built_renovated" ? 50 : isCustomWidthColumns ? 100 :150,
              renderCell: renderCellContent,
            };

            if (key === "building_class") {
              columnDef.renderEditCell = (params) => (
                <Select
                  className="w-full "
                  value={params.value || ""}
                  onChange={(e) =>
                    params.api.setEditCellValue({
                      ...params,
                      value: e.target.value,
                    })
                  }
                >
                  {" "}
                  <MenuItem value={null}>-</MenuItem>
                  <MenuItem value="Single Family">Single Family</MenuItem>
                  <MenuItem value="Townhome">Townhome</MenuItem>
                  <MenuItem value="High Rise">High Rise</MenuItem>
                </Select>
              );
            }

            if (key === "admin_fee" || key === "application_fee") {
              columnDef.renderCell = (params) => (
                <span>
                  {!isEmpty(params?.value) ? `$${params?.value}` : ""}
                </span>
              );
            }
            if (key === "one_and_a_half_bath") {
              columnDef = {
                field: key,
                headerName: "1.5 Bath",
                editable: true,
                width: 100,
                renderCell: renderCellContent,
              };
            }
            orderedColumns.push(columnDef);
          }
        });
    }

    // orderedColumns.push({
    //   field: "special_toggle",
    //   headerName: "",
    //   width: 70,
    //   sortable: false,
    //   renderCell: renderViewToggleButton,
    //   disableClickEventBubbling: true,
    // });

    orderedColumns.push({
      field: "blacklist123",
      headerName: "View Units",
      width: 80,
      sortable: false,
      renderCell: renderViewDetailsButton,
      disableClickEventBubbling: true,
    });

    orderedColumns.push({
      field: "star",
      headerName: "",
      width: 10,
      sortable: false,
      renderCell: renderStartButton,
      disableClickEventBubbling: true,
    });

    // orderedColumns.push({
    //   field: "blacklist",
    //   headerName: "Blacklist",
    //   width: 100,
    //   renderCell: renderBlacklistButton,
    // });
    ///orderedColumns.splice(8, 1);
    return orderedColumns;
  };

  const renderViewDetailsButton = (params) => {
    if (params.row.apartment_url != null && params.row.apartment_url != "") {
      return (
        <Button variant="contained" color="primary" size="small" onClick={toggleDrawer(true, params.row)} style={{ minWidth: '40px' }}>
          View
        </Button>
      );
    }
  };

  const renderStartButton = (params) => {
    if (params.row.apartment_url?.includes("sightmap")) {
      return <StarsIcon style={{ color: "blue" }} />;
    } else if (params.row.apartment_url?.includes("apartment")) {
      return (
        <div style={{ borderRadius: "50%" }}>
          <FontDownloadIcon style={{ color: "green", borderRadius: "50%" }} />
        </div>
      );
    } else if (params.row.apartment_url?.includes("realpage")) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            width: "100%",
          }}
        >
          <img src={realPageLogo} style={{ width: "20px" }} />
        </div>
      );
    }
  };

  const renderViewToggleButton = (params) => {
    return (
      <Button
      title="Calculated Special">
        <CalculateIcon
          onClick={(e) => {
            if(!specialData?.term_length_studio)
            setSpecialData(params.row);
            console.log(params.row,"bedCount");
            setCurrentId(params.row);
            setTermLengthProp(params.row.term_length);
            setMonthFeeProp(params.row.months_fee);
            setMonthFeeReferenceProp(params.row.months_fee_reference);
            setRentConcessionProp(params.row.rent_concession);
            setRentConcessionReferenceProp(
              params.row.rent_concession_reference
            );
            setIsDropdownOpen(true);
            // if (e.target.checked) {
            //   setTermLengthProp(params.row.term_length);
            //   setMonthFeeProp(params.row.months_fee);
            //   setMonthFeeReferenceProp(params.row.months_fee_reference);
            //   setRentConcessionProp(params.row.rent_concession);
            //   setRentConcessionReferenceProp(params.row.rent_concession_reference);
            //   setIsDropdownOpen(true);
            // }
            // else
            // {
            //   //const handleSubmit = async (submit, leaseTerm, monthsFree, rentConcession, monthsFreeReference, rentConcessionReference, bool) => {
            //     handleSubmit(params.row, "","","","","", false)
            // }
          }}
        />
      </Button>
      // <Switch title="Calculated Special" defaultChecked={params.row.special_toggle} onChange={(e) => {
      //   console.log(params.row);
      //   setCurrentId(params.row);
      //   if (e.target.checked) {
      //     setTermLengthProp(params.row.term_length);
      //     setMonthFeeProp(params.row.months_fee);
      //     setMonthFeeReferenceProp(params.row.months_fee_reference);
      //     setRentConcessionProp(params.row.rent_concession);
      //     setRentConcessionReferenceProp(params.row.rent_concession_reference);
      //     setIsDropdownOpen(true);
      //   }
      //   else
      //   {
      //     //const handleSubmit = async (submit, leaseTerm, monthsFree, rentConcession, monthsFreeReference, rentConcessionReference, bool) => {
      //       handleSubmit(params.row, "","","","","", false)
      //   }
      // }} />
    );
  };

  const toggleDrawer = (open, params) => (event) => { 
    setIsSightMap(params?.apartment_url_type);
    setCurrentRowData(params);
    if (params?.id) {
      setPropertyId(params?.id);
      var response = axios
        .post(`${process.env.REACT_APP_API_URL}/view-units/${params?.id}`, { filters: selectedBoolean})
        .then((res) => {
          let unitProperty = properties.find((x) => x.id == params?.id);
          //unitProperty.units = res.data
          setTempUnit(res.data);
          if(notAvailable)
          setCurrentUnit(res.data);
          else {
              if(params?.apartment_url_type === "RP" || params?.apartment_url_type === "SM")
              {
                setCurrentUnit(
                  res.data.filter(
                    (x) => x.archived == false
                  )
                );
              }
              else
              {
                setCurrentUnit(
                  res.data.filter(
                    (x) => !x?.unit_details?.includes("Not Available") && x.archived == false
                  )
                );
              }
            }
          setCurrentRow(properties.find((x) => x.id == params?.id));
          let bed = properties.find((x) => x.id == params?.id);
          if (bed != null) {
            setBedCount({
              studio: (!selectedBoolean.studio && !selectedBoolean.one_bed && !selectedBoolean.two_bed && !selectedBoolean.three_bed && !selectedBoolean.four_plus_bed) ? res.data.filter((x) => x.unit_studio == true).length : selectedBoolean.studio ? res.data.filter((x) => x.unit_studio == true).length : 0,
              one_bed: (!selectedBoolean.studio && !selectedBoolean.one_bed && !selectedBoolean.two_bed && !selectedBoolean.three_bed && !selectedBoolean.four_plus_bed) ? res.data.filter((x) => x.unit_bedroom == 1).length : selectedBoolean.one_bed ? res.data.filter((x) => x.unit_bedroom == 1).length : 0,
              two_bed: (!selectedBoolean.studio && !selectedBoolean.one_bed && !selectedBoolean.two_bed && !selectedBoolean.three_bed && !selectedBoolean.four_plus_bed) ? res.data.filter((x) => x.unit_bedroom == 2).length : selectedBoolean.two_bed ? res.data.filter((x) => x.unit_bedroom == 2).length : 0,
              three_bed: (!selectedBoolean.studio && !selectedBoolean.one_bed && !selectedBoolean.two_bed && !selectedBoolean.three_bed && !selectedBoolean.four_plus_bed) ? res.data.filter((x) => x.unit_bedroom == 3).length : selectedBoolean.three_bed ? res.data.filter((x) => x.unit_bedroom == 3).length : 0,
              four_plus_bed: (!selectedBoolean.studio && !selectedBoolean.one_bed && !selectedBoolean.two_bed && !selectedBoolean.three_bed && !selectedBoolean.four_plus_bed) ? res.data.filter((x) => x.unit_bedroom >= 4).length : selectedBoolean.four_plus_bed ? res.data.filter((x) => x.unit_bedroom >= 4).length : 0,
            });

          };
          let result = [];
          if (
            selectedBoolean.studio ||
            selectedBoolean.one_bed ||
            selectedBoolean.two_bed ||
            selectedBoolean.three_bed ||
            selectedBoolean.four_plus_bed
          ) {
            if (selectedBoolean.studio) {
              result = res.data.filter((x) => x.unit_studio == true);
            }
            if (selectedBoolean.one_bed) {
              result = result.concat(res.data.filter((x) => x.unit_bedroom == 1));
            }
            if (selectedBoolean.two_bed) {
              result = result.concat(res.data.filter((x) => x.unit_bedroom == 2));
            }
            if (selectedBoolean.three_bed) {
              result = result.concat(res.data.filter((x) => x.unit_bedroom == 3));
            }
            if (selectedBoolean.four_plus_bed) {
              result = result.concat(res.data.filter((x) => x.unit_bedroom >= 4));
            }
          }
          if (bed != null) {
            if (
              !selectedBoolean.studio &&
              !selectedBoolean.one_bed &&
              !selectedBoolean.two_bed &&
              !selectedBoolean.three_bed &&
              !selectedBoolean.four_plus_bed
            ) {
              let val =
                res.data.filter((x) => x.unit_studio == true).length > 0
                  ? 0
                  : res.data.filter((x) => x.unit_bedroom == 1).length > 0
                  ? 1
                  : res.data.filter((x) => x.unit_bedroom == 2).length > 0
                  ? 2
                  : res.data.filter((x) => x.unit_bedroom == 3).length > 0
                  ? 3
                  : 4;
              setCurrentIndex(val);
            } else {
              let val =
                result.filter((x) => x.unit_studio == true).length > 0
                  ? 0
                  : result.filter((x) => x.unit_bedroom == 1).length > 0
                  ? 1
                  : result.filter((x) => x.unit_bedroom == 2).length > 0
                  ? 2
                  : result.filter((x) => x.unit_bedroom == 3).length > 0
                  ? 3
                  : 4;
              setCurrentIndex(val);
            }
          }
          setHideTab(true);
          setIsDrawerOpen(open);
        })
        .catch((error) => {
          console.error("Error updating row:", error);
          throw new Error("Could not update row. Please try again.");
        });
    }
    
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open) {
      setTabIndex(0);
      setPropertyDetails({});
      setIsDrawerOpen(open);
      setNotAvailable(false);
    }
    
  };

  const viewDetails = async (rowData, open, refresh) => {
    setIsSightMap(rowData?.apartment_url_type);
    try {
      if (rowData?.id) {
        setPropertyId(rowData?.id);
        const response = await axios
          .post(`${process.env.REACT_APP_API_URL}/view-units/${rowData?.id}`)
          .then((res) => {
            let unitValue = properties.find((x) => x.id == rowData?.id);
            setTempUnit(res.data);
            if (notAvailable) setCurrentUnit(res.data);
            else {
              if(rowData?.apartment_url_type === "SM" || rowData?.apartment_url_type === "RP")
              {
                setCurrentUnit(
                  res.data.filter(
                    (x) => x.archived == false
                  )
                );
              }
              else
              {
                setCurrentUnit(
                  res.data.filter(
                    (x) => !x?.unit_details?.includes("Not Available") && x.archived == false
                  )
                );
              }
            }
            setCurrentRow(properties.find((x) => x.id == rowData?.id));
            let bed = properties.find((x) => x.id == rowData?.id);
            if (properties.find((x) => x.id == rowData?.id))
              unitValue["units"] = res.data;
            if (bed != null) {
              setBedCount({
                studio: unitValue.units.filter((x) => x.unit_studio == true)
                  .length,
                one_bed: unitValue.units.filter((x) => x.unit_bedroom == 1)
                  .length,
                two_bed: unitValue.units.filter((x) => x.unit_bedroom == 2)
                  .length,
                three_bed: unitValue.units.filter((x) => x.unit_bedroom == 3)
                  .length,
                four_plus_bed: unitValue.units.filter(
                  (x) => x.unit_bedroom >= 4
                ).length,
              });
            }
            if (bed != null) {
              let val =
                properties
                  .find((x) => x.id == rowData?.id)
                  .units.filter((x) => x.unit_studio == true).length > 0
                  ? 0
                  : properties
                      .find((x) => x.id == rowData?.id)
                      .units.filter((x) => x.unit_bedroom == 1).length > 0
                  ? 1
                  : properties
                      .find((x) => x.id == rowData?.id)
                      .units.filter((x) => x.unit_bedroom == 2).length > 0
                  ? 2
                  : properties
                      .find((x) => x.id == rowData?.id)
                      .units.filter((x) => x.unit_bedroom == 3).length > 0
                  ? 3
                  : 4;
              if(!refresh)
              setCurrentIndex(val);
            }
            setHideTab(true);
          });
      }
    } catch (error) {
      console.error("Error updating row:", error);
      throw new Error("Could not update row. Please try again.");
    }
  };
  

  const customSortComparator = (v1, v2) => {
    // Handle null or empty string values, ensuring they are sorted to the bottom
    const isNull1 = v1 === null || v1 === "";
    const isNull2 = v2 === null || v2 === "";
    if (isNull1 && isNull2) return 0;
    if (isNull1) return 1;
    if (isNull2) return -1;

    const isPercent1 = v1.includes("%");
    const isPercent2 = v2.includes("%");
    const num1 = parseFloat(v1.replace(/[%$]/g, ""));
    const num2 = parseFloat(v2.replace(/[%$]/g, ""));

    // Prioritize percentages over dollar values
    if (isPercent1 && !isPercent2) return -1;
    if (!isPercent1 && isPercent2) return 1;

    // For values of the same type, compare their numerical parts
    return num1 > num2 ? -1 : num1 < num2 ? 1 : 0;
  };

  const handleCellClick = (params, event) => {
    if (params.field === 'blacklist123') {
      event.stopPropagation(); // Prevent row selection for Column 1
    }
  };
  
  const handleRowUpdate = async (newRow, oldRow, isGarageOrYard, category) => {
    delete newRow.units;
    delete newRow.models;
    delete newRow.move_in_special;
    if(category != null && category !== "")
      newRow.category = category;
    if (!user || user.role !== "admin") {
      console.log("User is not authorized to update rows.");
    } else {
      try {
        // Make an API call to update the backend
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/property/${newRow.id}`,
           {data: newRow}
        );

        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === newRow?.id);
          if (index > -1) {
            oldData[index] = newRow;
          }
          return oldData;
        });
        await fetchData();     
        return response?.data;
      } catch (error) {
        console.error("Error updating row:", error);
        throw new Error("Could not update row. Please try again.");
      }
    }
  };
  const handleMultiRowUpdate = async (updatedRows) => {
    if (!user || user.role !== "admin") {
      console.log("User is not authorized to update rows.");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/property/batchUpdate`,
          { rows: updatedRows }
        );
        await fetchData();
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          updatedRows.forEach((newRow) => {
            const index = oldData.findIndex((x) => x?.id === newRow?.id);
            if (index > -1) {
              oldData[index] = newRow;
            }
          });
          return oldData;
        });

        return response?.data;
      } catch (error) {
        console.error("Error updating rows:", error);
        throw new Error("Could not update rows. Please try again.");
      }
    }
  };
  const generateCsvName = () =>{
    const date = new Date();
    const formattedDate = `${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}`;
    return `sidekick_${formattedDate}.csv`
}

const openTabVirtualTour = async (e) => {
  if (e.length > 0) {
    let delay = 2000;
    e.forEach((url, index) => {
      setTimeout(() => {
        window.open(url.link, "_blank");
      }, index * delay);
    });
  }
};

  const CustomToolbar = ({ apiRef }) => {
 
    const handleExport = async () => {
      try {
         
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/export-csv`, {
              responseType: 'blob'  
          });
  
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', generateCsvName()); 
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      } catch (error) {
          console.error('Error downloading the CSV file:', error);
      }
  };

 

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          
            <Button color="primary" onClick={handleExport} startIcon={<SaveIcon />}>
                Export
            </Button>
         
        </GridToolbarContainer>
    );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width ? 400 : 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: 800
};

const handleTabChange = async (event, newIndex) => {
  if(event.target.innerText == "STUDIO")
    setCurrentIndex(0);
  if(event.target.innerText == "1 BED")
    setCurrentIndex(1);
  if(event.target.innerText == "2 BED")
    setCurrentIndex(2);
  if(event.target.innerText == "3 BED")
    setCurrentIndex(3);
  if(event.target.innerText == "4 BED")
    setCurrentIndex(4);
  setTabIndex(newIndex);
  var tabValue = unitFilter;
  tabValue.current = newIndex;
  unitFilter = tabValue;
  //viewDetails(currentRow);
};
const copyUnit = (e, type) => {
  handleActionOneUnit(currentRow, e, type);
}

const handleActionOneUnit = (rowData,unit,type) => {
  let units;
  let sqft;
  if (type == "main") {
    units = unit.units;
    sqft = unit.square_feet;
  }
  else
  {
    units = unit.split("_")[0];
    sqft = unit.split("_")[1];
  }
  // Define HTML content with inline styles
  const emailContent = `
    <div style="font-size: 16px; font-family: 'Calibri', sans-serif;">
      <p style="font-weight: 700; margin: 0; padding: 0"><a href="${rowData?.website}">${rowData?.name ? (rowData?.name + " (" + rowData.website+ ")") : " "}</a>${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "")}</p>
      <p style="margin: 0; padding: 0">Price: $ <span style="font-weight: 700;">${rowData?.special_toggle ? rowData?.special_calculation_text : rowData?.special ? rowData?.special : ""}</span></p>
      <p style="margin: 0; padding: 0">Size: sq ft 1/1</p>${sqft}
      <p style="margin: 0; padding: 0">Unit: #</p>${units}
      <p style="margin: 0; padding: 0">Available: - ${rowData?.hold_time ? rowData?.hold_time : ""}</p>
      <p style="margin: 0; padding: 0">Location: ${rowData?.submarket ? rowData?.submarket : ""}</p>
      <p style="margin: 0; padding: 0">${rowData?.last_line ? rowData?.last_line : ""}</p>
    </div>
  `;

  const plainTextContent = `${
    rowData?.name ? `${rowData.name} (${rowData.website}) ${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "*if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit." : "")}` : ""
  }
Price: $ ${rowData?.special_toggle ? rowData?.special_calculation_text : rowData?.special ? rowData?.special : ""}
Size: ${sqft} sq ft 1/1
Unit: ${units}
Available: - ${rowData?.hold_time ? rowData?.hold_time : ""}
Location: ${rowData?.submarket ? rowData?.submarket : ""}
${rowData?.last_line ? rowData?.last_line : ""}`;

  navigator.clipboard
    .write([
      new ClipboardItem({
        "text/html": new Blob([emailContent], { type: "text/html" }),
        "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
      }),
    ])
    .then(() => {
      console.log("Email info copied to clipboard!");
      setSnackbarOpen(true);
    })
    .catch((err) => {
      console.error("Failed to copy email info: ", err);
    });
};

const toggleExpand = (index) => {
  const newExpandState = [...isExpanded];
  newExpandState[index] = !newExpandState[index];
  setIsExpanded(newExpandState);
};

const loadSwitch = () => {
  setHideTab(false);
  setCurrentRow([]);
  setCurrentUnit([]);
  viewDetails(currentRowData, true, true);
}
const handleCellDoubleClick = (params) => {
  setCurrentSelectedData(params);
  if (
    params?.field !== "category" &&
    params?.field !== "virtual_tour_edit" &&
    params?.field !== "virtual_tour" &&
    params?.field !== "send" &&
    params?.field !== "escort"
  ) {
    if(typeof params.value != "boolean")
      setSelectedCellData(params.value);
      if(typeof params.value == "boolean")
        setSelectedCellData(params.value ? "Yes" : "No");
      setIsDoubleClickPopupOpen(true);
  }
};

const handleCloseDoubleClick = () => {
  setIsDoubleClickPopupOpen(false);
};
  return (
    <div className=" mx-auto py-4 bg-white rounded-lg">
      <Modal
        open={isDropdownOpen}
        onClose={() => {
          setIsDropdownOpen(false);
          // setFilteredProperties((prev) => {
          //   const oldData = [...prev];
          //   const index = oldData.findIndex((x) => x?.id === currentId?.id);
          //   let newRow = oldData.find((x) => x?.id === currentId?.id);
          //   newRow.special_toggle = false;
          //   if (index > -1) {
          //     oldData[index] = newRow;
          //   }
          //   return oldData;
          // });
          // fetchData();
          setCurrentId({});
          handleCancel();
          setWidth(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {modalLoader && (
            <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress disableShrink />
          </Box>
          )}
          {!modalLoader && (
            <ProrationCalculator
            monthFee={monthFeeProp}
            monthFeeReference={monthFeePropReferenceProp}
            rentConcessions={rentConcessionProp}
            rentConcessionReferences={rentConcessionReferenceProp}
            termLength={termLengthProp}
            isSpecial={true}
            specialData={specialData}
            submit={handleSubmit}
            cancel={handleCancel}
            reduceWidth={reduceWidth}
          />
          )}
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
      <Snackbar
        open={linkSnackBarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseLinkSnackbar}
        message="Copied to clipboard!"
      />
      <div>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ "& .MuiDrawer-paper": { width: "80%", boxShadow: "unset" } }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <Box
            sx={{ width: "60vw" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          ></Box>
          <div>
            <div>
              <div
                style={{
                  marginTop: "20px",
                  color: "#4e5c76",
                  fontSize: "40px",
                  marginLeft: "30px",
                  marginRight: "30px",
                  fontWeight: "800",
                  boxShadow: "unset",
                }}
              >
                {currentRow?.name}
              </div>
              <div>
                <div
                  class="grid grid-cols-4 gap-4"
                  style={{
                    paddingBottom: "20px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                  }}
                >
                  {currentRow && currentRow.address && (
                    <div>
                      <LocationOnIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      {currentRow.address}
                    </div>
                  )}
                  {currentRow && currentRow.website && (
                    <div>
                      <LanguageIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <a
                        href={currentRow.website}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        Website
                      </a>
                    </div>
                  )}
                  {currentRow && currentRow.google_maps_url && (
                    <div>
                      <MapIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <a
                        href={currentRow.google_maps_url}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        Map
                      </a>
                    </div>
                  )}
                  {currentRow && currentRow?.virtual_tour && (
                    <div>
                      <VideocamIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <span
                        onClick={() =>
                          openTabVirtualTour(currentRow.virtual_tour)
                        }
                        style={{ textDecoration: "underline" }}
                      >
                        Virtual Tour
                      </span>
                    </div>
                  )}
                  {currentRow && currentRow.phone && (
                    <div>
                      <PhoneIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        {currentRow.phone}
                      </span>
                    </div>
                  )}
                  {currentRow && currentRow.email && (
                    <div>
                      <MarkunreadIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        {currentRow.email}
                      </span>
                    </div>
                  )}
                  {currentRow && currentRow.year_built && (
                    <div>
                      <CalendarMonthIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Build Year: &nbsp;
                      {currentRow.year_built}
                    </div>
                  )}
                  {currentRow && currentRow.escort && (
                    <div>
                      <SecurityIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Escort %: &nbsp;{currentRow.escort}
                    </div>
                  )}
                  {currentRow && currentRow.send && (
                    <div>
                      <SecurityIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Send %: &nbsp;{currentRow.send}
                    </div>
                  )}
                  {currentRow && currentRow.hold_time && (
                      <div>
                        <AccessTimeIcon style={{ color: "#4e5c76" }} />
                        &nbsp;&nbsp; Hold Time: &nbsp;{currentRow.hold_time}
                      </div>
                    )}
                </div>
              </div>
              {data && (
                <div
                  style={{
                    margin: "30px",
                    height: "10vh",
                    display: "flex",
                    alignItems: "center",
                    background: "#f5fbfc",
                    border: ".0625rem dashed #0f8ac0",
                  }}
                >
                  <div style={{ marginLeft: "20px", display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <LocalOfferIcon style={{ color: "#4e5c76" }} />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <div style={{ fontSize: "20px" }}>Move-in Special</div>
                      <div style={{ fontSize: "15px" }}>{data}</div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Switch
                  onChange={() => {
                    if (!notAvailable) setCurrentUnit(tempUnit);
                    else {
                      if(isSightMap)
                      {
                        setCurrentUnit(
                          tempUnit?.filter(
                            (x) => x.archived == false
                          )
                        );
                      }
                      else
                      {
                        setCurrentUnit(
                          tempUnit?.filter(
                            (x) => !x?.unit_details?.includes("Not Available") && x.archived == false
                          )
                        );
                      }
                    }
                    setNotAvailable(!notAvailable);
                  }}
                />
                <label style={{ marginRight: "30px", fontWeight: 800 }}>
                  View Unavailable Units
                </label>
              </div>
              {!hideTab && (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress disableShrink />
                </Box>
              )}
              {hideTab && (
                <>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{
                      backgroundColor: "white",
                      color: "#4e5c76",
                      "& .MuiTab-root": {
                        backgroundColor: "#f8f8f8",
                        color: "#4e5c76",
                        "&.Mui-selected": {
                          backgroundColor: "#4e5c76",
                          color: "white",
                        },
                      },
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    {bedCount.studio > 0 && (
                      <Tab
                        label="STUDIO"
                        style={{ borderTopLeftRadius: "20px" }}
                      />
                    )}
                    {bedCount.one_bed > 0 && (
                      <Tab
                        label="1 BED"
                        style={{
                          borderTopLeftRadius:
                            bedCount.studio == 0 ? "20px" : "",
                          borderTopRightRadius:
                            bedCount.two_bed == 0 &&
                            bedCount.three_bed == 0 &&
                            bedCount.four_plus_bed == 0
                              ? "20px"
                              : "",
                        }}
                      />
                    )}
                    {bedCount.two_bed > 0 && (
                      <Tab
                        label="2 BED"
                        style={{
                          borderTopLeftRadius:
                            bedCount.studio == 0 && bedCount.one_bed == 0
                              ? "20px"
                              : "",
                          borderTopRightRadius:
                            bedCount.three_bed == 0 &&
                            bedCount.four_plus_bed == 0
                              ? "20px"
                              : "",
                        }}
                      />
                    )}
                    {bedCount.three_bed > 0 && (
                      <Tab
                        label="3 bed"
                        style={{
                          borderTopLeftRadius:
                            bedCount.studio == 0 &&
                            bedCount.one_bed == 0 &&
                            bedCount.two_bed == 0
                              ? "20px"
                              : "",
                          borderTopRightRadius:
                            bedCount.four_plus_bed == 0 ? "20px" : "",
                        }}
                      />
                    )}
                    {bedCount.four_plus_bed > 0 && (
                      <Tab
                        label="4 bed"
                        style={{ borderTopRightRadius: "20px" }}
                      />
                    )}
                  </Tabs>
                  <TabPanel value={tabIndex} index={0}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      virtual={handleVirtual}
                      localLink={localLink}
                      driveData={driveData}
                      loadSwitch={loadSwitch}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      localLink={localLink}
                      virtual={handleVirtual}
                      loadSwitch={loadSwitch}
                      driveData={driveData}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={2}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      localLink={localLink}
                      virtual={handleVirtual}
                      loadSwitch={loadSwitch}
                      driveData={driveData}
                    />
                  </TabPanel>

                  <TabPanel value={tabIndex} index={3}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      localLink={localLink}
                      virtual={handleVirtual}
                      onData={handleDataFromChild}
                      loadSwitch={loadSwitch}
                      driveData={driveData}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={4}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      localLink={localLink}
                      virtual={handleVirtual}
                      loadSwitch={loadSwitch}
                      driveData={driveData}
                    />
                  </TabPanel>
                </>
              )}
            </div>
          </div>
        </Drawer>
      </div>
      <Box>
        <Box
          sx={{
            marginBottom: "10px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Button
            onClick={copyProperties}
            variant="contained"
            disabled={selectedRows.length === 0}
          >
            Copy Properties
          </Button>
          <Button
            onClick={openAllFloorplanLinks}
            variant="contained"
            disabled={selectedRows.length === 0}
          >
            Open All Floorplan Links
          </Button>
        </Box>
        <Box
          sx={{ height: "80vh", width: "100%" }}
          onContextMenu={handleContextMenu}
          style={{ cursor: "context-menu" }}
        >
          {properties.length > 0 && (
            <DataGridPro
              initialState={{
                columns: {
                  columnVisibilityModel: generateColumnVisibilityModel(),
                },

                pinnedColumns: {
                  left: ["__check__", "actions", "actions_copy_mail", "name", "star", "blacklist123"],
                },
              }}
              onCellDoubleClick={handleCellDoubleClick}
              rows={properties.map((property, index) => ({
                id: property.id || index,
                ...property,
              }))}
              columns={generateColumns()}
              pageSize={paginationModel.pageSize}
              checkboxSelection
              components={{
                Toolbar: CustomToolbar,
              }}
              onCellClick={handleCellClick}
              onRowSelectionModelChange={(newSelection) => {
                const selectedRowsData = properties.filter((row) =>
                  newSelection.includes(row.id)
                );
                setSelectedRows(selectedRowsData);
              }}
              //processRowUpdate={handleRowUpdate}
              experimentalFeatures={{ newEditingApi: true }}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={(e) => {
                setPaginationModel((prev) => {
                  if (e.pageSize != prev?.pageSize) {
                    return {
                      page: 0,
                      pageSize: e.pageSize,
                    };
                  }
                  return e;
                });
              }}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              rowCount={totalRows}
              loading={loading}
              getRowClassName={(params) => {
                const currentRowIndex =
                  properties.length > 0
                    ? properties.findIndex((row) => row.id === params.id)
                    : 0;
                return currentRowIndex % 2 === 0 ? "even-row" : "odd-row";
              }}
              {...properties}
            />
          )}
          <div className="custom-menuitem">
            {selectedRows.length > 0 && (
              <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
              >
                <MenuItem
                  style={{
                    height: 31,
                  }}
                  onClick={() => handleCategoryChange("Change Category")}
                >
                  Change Category
                </MenuItem>

                {subMenuOpen &&
                  showCategory !== null &&
                  showCategory === "Change Category" && (
                    <Menu
                      open={subMenuOpen}
                      onClose={handleSubMenuClose}
                      anchorReference="anchorPosition"
                      anchorPosition={{
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX + 160,
                      }}
                    >
                      <MenuItem onClick={() => handleApplyCategory("null")}>
                        -
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleApplyCategory("Not working with locators")
                        }
                      >
                        Not working with locators
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleApplyCategory("Student Housing")}
                      >
                        Student Housing
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleApplyCategory("Affordable Housing")
                        }
                      >
                        Affordable Housing
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleApplyCategory("Do not add")}
                      >
                        Do not add
                      </MenuItem>
                      <MenuItem onClick={() => handleApplyCategory("Unsure")}>
                        Unsure
                      </MenuItem>
                    </Menu>
                  )}
              </Menu>
            )}
          </div>
        </Box>
      </Box>
      {isPopupOpen && (
        <div className="popup">
          <Dialog open={isPopupOpen} onClose={handleClosePopup}>
            <DialogTitle className="flex flex-row justify-between">
              <>Edit Virtual Tour</>
              <IconButton onClick={handleAdd}>
                <AddIcon style={{ color: "#0075FF" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                {tours.map((tour, propertyIndex) => (
                  <div
                    key={propertyIndex}
                    className="flex gap-10 "
                    style={{ margin: 16 }}
                  >
                    <TextField
                      name={`tours.${propertyIndex}.name`}
                      label={`Virtual Tour Name ${propertyIndex + 1}`}
                      value={tour?.name}
                      onChange={(e) =>
                        handleChange(
                          propertyIndex,
                          "name",
                          e.target.value.replace(/^\s+/, "")
                        )
                      }
                    />
                    <TextField
                      name={`tours.${propertyIndex}.link`}
                      label={`Virtual Tour Link ${propertyIndex + 1}`}
                      value={tour?.link}
                      onChange={(e) =>
                        handleChange(
                          propertyIndex,
                          "link",
                          e.target.value.replace(/^\s+/, "")
                        )
                      }
                    />

                    <IconButton onClick={() => handleRemove(propertyIndex)}>
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  </div>
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePopup}>Cancel</Button>
              <Button
                onClick={handleSavePopup}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Modal
        open={isPopupDoubleClickOpen}
        onClose={handleCloseDoubleClick}
        aria-labelledby="popup-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            padding: 4,
            borderRadius: 1,
          }}
        >
          <Typography id="popup-title" variant="h6" component="h2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Edit - {currentSelectedData?.row?.name} - {currentSelectedData?.colDef?.headerName}
          </Typography>
          <Typography variant="body1">
            <textarea rows={10} cols={80} onChange={(e) => {
              setSelectedCellData(e.target.value);
              setCurrentSelectedData((prevState) => ({
                ...prevState,
                row: {
                  ...prevState.row,
                  [currentSelectedData?.field]: e.target.value,
                },
              }));
            }}>
              {selectedCellData}
            </textarea>
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Button variant="contained" color="primary" onClick={() => {
              handleRowUpdate(currentSelectedData?.row, {}, currentSelectedData?.field)
              setIsDoubleClickPopupOpen(false);
            }}>
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Properties;
